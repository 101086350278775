export default {
  // Base url path
  // baseUrl: 'http://localhost:3000/nodeapp',
  baseUrl: 'https://mycaretrackhealth.com/beta_nodeapp',
  // baseUrl: 'http://192.168.150.62:5000/nodeapp',
  // baseUrl: 'http://192.168.150.18:3000/nodeapp', // Andrei Maquina
  // baseUrl: 'http://localhost:4000/nodeapp', // Sebastian Maquina

  // Endpoints
  loginEndpoint: '/api/v1/users/login',
  verifyAuthyCodeEndpoint: '/api/v1/users/verify',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',
  uploadFile: '/api/v1/lists/upload-files',
  getCounts: '/api/v1/lists/get-counts',
  getIpaTemplates: '/api/v1/lists/get-ipa-templates',
  getSelectedIpaTemplate: '/api/v1/lists/get-selected-ipa-template',
  setDefaultTemplate: '/api/v1/lists/set-default-template',
  createIpaTemplate: '/api/v1/lists/add-ipa-template',
  getFilesDateCounts: '/api/v1/lists/get-files-date-counts',
  getProfiles: '/api/v1/profiles/get-profiles',
  getProfileInfo: '/api/v1/profiles/get-profile-info',
  getApptHistory: '/api/v1/appointments/get-appt-history',
  getUsersLogged: 'https://beta.mycaretrackhealth.com/vista/reportes/loginRegister.php',

  // Catalogs
  getAll: '/api/v1/catalogs/get-all',
  deleteElement: '/api/v1/catalogs/delete-element',
  getOneElement: '/api/v1/catalogs/get-one',
  getCatalogSelect: '/api/v1/catalogs/get-catalog-select',
  createUpdateElement: '/api/v1/catalogs/create-update-element',
  createUpdateProvider: '/api/v1/catalogs/create-update-provider',
  createUpdateSite: '/api/v1/catalogs/create-update-site',

  // User
  getUsers: '/api/v1/users/get-users',
  getUser: '/api/v1/users/get-user',
  getUserProfiles: '/api/v1/users/get-user-profiles',
  signUpUser: '/api/v1/users/signUp',
  getUserTeams: '/api/v1/users/get-user-teams',
  updateUser: '/api/v1/users/update-user',
  deleteUserProfile: '/api/v1/users/delete-user',
  editByPass: '/api/v1/users/edit-user-bypass',
  getUserSpecialPermissions: '/api/v1/users/get-user-special-permissions',
  editUserPermission: '/api/v1/users/edit-user-permissions',

  // Permissions
  getModulesPermission: '/api/v1/permissions/get-modules-permissions',
  getPermissionProfiles: '/api/v1/permissions/get-profiles',
  createPermissionProfile: '/api/v1/permissions/create-profile',
  updatePermissionProfile: '/api/v1/permissions/update-profile',
  deletePermissionProfile: '/api/v1/permissions/delete-profile',

  // Crm DashBoard
  getCrmDashboardTotals: '/api/v1/crm/totals',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
